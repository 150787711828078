import React from 'react'

//Components
import UserDash from '../components/userdash/UserDash';





function Dashboard() {
   
  return (
    <div>
        <UserDash/>
    </div>
  )
}

export default Dashboard